import { css } from "@emotion/react";
import type { Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { BaseLink } from "scmp-app/components/common/base-link";
import { DynamicHeaderOnelineMenuContainer } from "scmp-app/components/header/dynamics";
import { Divider } from "scmp-app/components/header/header-oneline-menu-container/styles";
import type { LogoVariant } from "scmp-app/components/header/types";
import { SignInText } from "scmp-app/components/home/header/header-avatar/styles";
import LogoFlag from "scmp-app/components/home/header/icon-logo-flag.svg";
import Logo from "scmp-app/components/home/header/icon-logo.svg";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";

type Props = {
  theme: Theme;
};

export const StyledDynamicHeaderOnelineMenuContainer = styled(DynamicHeaderOnelineMenuContainer)`
  display: none;

  margin-inline-start: 0;

  ${Divider} {
    margin-inline: 14px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-inline-end: 20.5px;
  }
`;

export const StyledLogo = styled(Logo)`
  inline-size: 207px;
  block-size: 22px;
`;

export const StyledLogoFlag = styled(LogoFlag)`
  display: inline-block;

  inline-size: 28.43px;
  block-size: 21.54px;

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 39px;
    block-size: 30px;
  }
`;

export type LogoProps = {
  $responsiveVariants: ResponsiveVariants<LogoVariant>;
};

const logoLink = (props: Props) => css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  ${props.theme.breakpoints.up("tablet")} {
    align-items: flex-start;
  }
`;

export const StyledLogoLink = styled(BaseLink)`
  ${logoLink}

  display: none;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: flex;
  }
`;

export const MobileStyledLogoLink = styled(BaseLink)`
  ${logoLink}
  position: absolute;
  inset-inline-start: 50%;

  display: flex;

  transform: translateX(-50%);

  ${props => props.theme.breakpoints.up("tablet")} {
    display: none;
  }
`;

export const Container = styled.div`
  ${setFullViewportWidthStyles()}
  background-color: transparent;

  border-block-end: 1px solid #000000;

  background-color: #ffffff;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-inline: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  inline-size: 100%;
  block-size: 45px;
  max-inline-size: 1344px;
  margin-inline: auto;
  padding-inline: 20px;
  box-sizing: border-box;

  ${props => props.theme.breakpoints.up("tablet")} {
    block-size: 58px;
    padding-inline: 32px;
  }
`;

export const LeftFeatureContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;

  min-inline-size: 0;

  ${props => props.theme.breakpoints.up("tablet")} {
    gap: 20px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    gap: 16px;
  }
`;

export const RightFeatureContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  ${props => props.theme.breakpoints.up("tablet")} {
    & > div:not(:first-child) {
      margin-inline-start: 20px;
    }

    ${SignInText} {
      display: none;
    }
  }
`;

import { useScroll } from "ahooks";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { BaseLink } from "scmp-app/components/common/base-link";
import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { DynamicHeaderAvatar } from "scmp-app/components/header/dynamics";
import { HeaderContentBookmark } from "scmp-app/components/header/header-content-bookmark";
import { HeaderContentCommentTrigger } from "scmp-app/components/header/header-content-comment-trigger";
import { HeaderContentShareWidgetDrawer } from "scmp-app/components/header/header-content-share-widget-drawer";
import { HeaderMyNews } from "scmp-app/components/header/header-my-news";
import {
  ActionButton,
  AvatarContainer,
  HeaderArticleSpeechContainer,
  StyledIconSearch,
} from "scmp-app/components/header/styles";
import type { ActionButtonProps, LogoProps } from "scmp-app/components/header/styles";
import type {
  Feature,
  HeaderConfiguration,
  ResponsiveFeature,
  ResponsiveLogoVariant,
} from "scmp-app/components/header/types";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { SearchEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { DynamicSubscriptionButton } from "scmp-app/lib/sdk/dynamics";
import type { OptionalBreakpointKey, RequiredBreakpointKey } from "scmp-app/lib/styles/responsive";
import { page as SearchPage } from "scmp-app/pages/search";

import { HeaderArticleSpeechContainerElementID } from "./consts";

export const useSwapOnScrollEffect = (threshold: number) => {
  const { top } = useScroll() ?? { top: 0 };
  return { shouldSwap: top >= threshold };
};

export const useHeader = (shouldSwap: boolean, headerConfiguration?: HeaderConfiguration) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const isScmpSubscriber = asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false;
  const featureConfigurations = useMemo(() => {
    // If not provided, use default responsive features
    const responsiveFeatures: ResponsiveFeature = {
      desktopUp: {
        default: ["subscription", "search", "dashboard"],
      },
      largeDesktopUp: {
        default: ["subscription", "search", "my-news", "dashboard"],
      },
      mediumDesktopUp: {
        default: ["subscription", "search", "my-news", "dashboard"],
      },
      mobileUp: {
        default: ["dashboard"],
      },
      tabletUp: {
        default: ["subscription", "my-news", "dashboard"],
      },
      ...headerConfiguration?.responsiveFeatures,
    };

    const computeFeatureConfiguration = (feature: Feature) => {
      const computeFeatureListForBreakpoint = (
        breakpoint: RequiredBreakpointKey | OptionalBreakpointKey,
      ) => {
        const featureForBreakpoint = responsiveFeatures[breakpoint];
        if (!featureForBreakpoint) return [];

        if (shouldSwap) {
          if (isScmpSubscriber) {
            return featureForBreakpoint.swapped ?? featureForBreakpoint.default;
          }
          return featureForBreakpoint.nonSubscriberSwapped ?? featureForBreakpoint.default;
        }
        return featureForBreakpoint.default;
      };

      const responsiveVariants: ActionButtonProps["$responsiveVariants"] = {
        desktopUp: computeFeatureListForBreakpoint("desktopUp").includes(feature)
          ? "show"
          : "hidden",
        largeDesktopUp: computeFeatureListForBreakpoint("largeDesktopUp").includes(feature)
          ? "show"
          : "hidden",
        mediumDesktopUp: computeFeatureListForBreakpoint("mediumDesktopUp").includes(feature)
          ? "show"
          : "hidden",
        mobileUp: computeFeatureListForBreakpoint("mobileUp").includes(feature) ? "show" : "hidden",
        tabletUp: computeFeatureListForBreakpoint("tabletUp").includes(feature) ? "show" : "hidden",
      };

      const isEnabled = Object.values(responsiveVariants).includes("show");

      return {
        isEnabled,
        responsiveVariants,
      };
    };

    const subscription = computeFeatureConfiguration("subscription");
    const dashboard = computeFeatureConfiguration("dashboard");
    const search = computeFeatureConfiguration("search");
    const myNews = computeFeatureConfiguration("my-news");
    const contentShare = computeFeatureConfiguration("content-share");
    const contentBookmark = computeFeatureConfiguration("content-bookmark");
    const contentComment = computeFeatureConfiguration("content-comment");
    const articleSpeech = computeFeatureConfiguration("article-speech");

    return {
      articleSpeech,
      contentBookmark,
      contentComment,
      contentShare,
      dashboard,
      myNews,
      search,
      subscription,
    };
  }, [headerConfiguration, isScmpSubscriber, shouldSwap]);

  const currentPageType = useCurrentPageType();
  const mastheadQueryParameter = {
    module: tracking.module.Masthead,
    pgtype: currentPageType,
  };

  const handleSearchClick = () => {
    // GA4 21.1.1
    sendGA4Tracking({
      action: "click",
      category: "search",
      subcategory: "icon",
    } as SearchEvent);
  };

  const renderFeatures = (): undefined | JSX.Element => {
    if (!featureConfigurations) return;
    const {
      articleSpeech,
      contentBookmark,
      contentComment,
      contentShare,
      dashboard,
      myNews,
      search,
      subscription,
    } = featureConfigurations;
    const { pathname: searchPathname, query: searchQuery } = SearchPage.route({});
    return (
      <>
        <IdleQueue>
          {subscription.isEnabled && (
            <ActionButton $responsiveVariants={subscription.responsiveVariants}>
              <DynamicSubscriptionButton
                variant={
                  headerConfiguration?.appBarVariant === "scmp/magazines-style" ? "dark" : undefined
                }
              />
            </ActionButton>
          )}
        </IdleQueue>

        {search.isEnabled && (
          <ActionButton $responsiveVariants={search.responsiveVariants}>
            <BaseLink
              anchorProps={{ "aria-label": "search" }}
              as="/search"
              pathname={searchPathname}
              query={{ ...searchQuery, ...mastheadQueryParameter }}
              onClick={handleSearchClick}
            >
              <StyledIconSearch />
            </BaseLink>
          </ActionButton>
        )}

        {myNews.isEnabled && (
          <ActionButton $responsiveVariants={myNews.responsiveVariants}>
            <BaseLink
              anchorProps={{ "aria-label": "my news" }}
              as="/mynews"
              pathname="mynews"
              query={mastheadQueryParameter}
            >
              <HeaderMyNews />
            </BaseLink>
          </ActionButton>
        )}

        {/* This is by intention to not condition render because it will have race condition if the portal container is not exist */}
        <ActionButton $responsiveVariants={articleSpeech.responsiveVariants}>
          <HeaderArticleSpeechContainer id={HeaderArticleSpeechContainerElementID} />
        </ActionButton>
        {contentComment.isEnabled && (
          <ActionButton $responsiveVariants={contentComment.responsiveVariants}>
            <HeaderContentCommentTrigger />
          </ActionButton>
        )}
        {contentShare.isEnabled && (
          <ActionButton $responsiveVariants={contentShare.responsiveVariants}>
            <HeaderContentShareWidgetDrawer />
          </ActionButton>
        )}
        {contentBookmark.isEnabled && (
          <ActionButton $responsiveVariants={contentBookmark.responsiveVariants}>
            <HeaderContentBookmark />
          </ActionButton>
        )}
        {dashboard.isEnabled && (
          <ActionButton $responsiveVariants={dashboard.responsiveVariants}>
            <AvatarContainer>
              <IdleQueue>
                <DynamicHeaderAvatar />
              </IdleQueue>
            </AvatarContainer>
          </ActionButton>
        )}
      </>
    );
  };

  const responsiveLogoVariant = useMemo(() => {
    // If not provided, use default responsive logo variant
    const responsiveLogoVariant: ResponsiveLogoVariant = {
      desktopUp: {
        default: "logo",
      },
      largeDesktopUp: {
        default: "logo",
      },
      mobileUp: {
        default: "full",
      },
      tabletUp: {
        default: "full",
      },
      xLargeDesktopUp: {
        default: "full",
      },
      ...headerConfiguration?.responsiveLogoVariant,
    };

    const computeVariantForBreakpoint = (
      breakpoint: RequiredBreakpointKey | OptionalBreakpointKey,
    ) => {
      const variantForBreakpoint = responsiveLogoVariant[breakpoint];
      if (!variantForBreakpoint) return "full";
      const features = shouldSwap
        ? variantForBreakpoint.swapped ?? variantForBreakpoint.default
        : variantForBreakpoint.default;
      return features;
    };

    const responsiveVariants: LogoProps["$responsiveVariants"] = {
      desktopUp: computeVariantForBreakpoint("desktopUp"),
      largeDesktopUp: computeVariantForBreakpoint("largeDesktopUp"),
      mobileUp: computeVariantForBreakpoint("mobileUp"),
      tabletUp: computeVariantForBreakpoint("tabletUp"),
      xLargeDesktopUp: computeVariantForBreakpoint("xLargeDesktopUp"),
    };

    return responsiveVariants;
  }, [headerConfiguration, shouldSwap]);

  return {
    featureConfigurations,
    mastheadQueryParameter,
    renderFeatures,
    responsiveLogoVariant,
  };
};

import { NoSsr } from "@mui/base";
import { MiniHeaderIndicatorContextProvider } from "@product/scmp-sdk";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import type { CSSProperties, FunctionComponent } from "react";

import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { hamburgerMenuAtom } from "scmp-app/components/hamburger-menu/atoms";
import { DefaultHeaderSwapThreshold } from "scmp-app/components/header/consts";
import { useSwapOnScrollEffect } from "scmp-app/components/header/hooks";
import type { HeaderConfiguration } from "scmp-app/components/header/types";
import IconMenu from "scmp-app/components/home/header/header-icon/menu.svg";
import { useHomeHeader } from "scmp-app/components/home/header/hooks";
import { ActionButton } from "scmp-app/components/home/header/styles";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useEditionValue } from "scmp-app/lib/edition";
import { useGetHomeAsPath } from "scmp-app/lib/router/hooks";
import { page as HomePage } from "scmp-app/pages/home";

import {
  Container,
  LeftFeatureContainer,
  MobileStyledLogoLink,
  RightFeatureContainer,
  StyledDynamicHeaderOnelineMenuContainer,
  StyledLogo,
  StyledLogoFlag,
  StyledLogoLink,
  Wrapper,
} from "./styles";

export type Props = {
  className?: string;
  headerConfiguration?: HeaderConfiguration;
  shouldShowRightFeature?: boolean;
  style?: CSSProperties;
};

export const MiniHeader: FunctionComponent<Props> = ({
  className,
  headerConfiguration,
  shouldShowRightFeature,
  style,
}) => {
  const edition = useEditionValue();
  const homeAsPath = useGetHomeAsPath();
  const router = useRouter();

  const setHamburgerMenuState = useSetAtom(hamburgerMenuAtom);

  const { shouldSwap } = useSwapOnScrollEffect(DefaultHeaderSwapThreshold);
  const { mastheadQueryParameter, renderFeatures } = useHomeHeader(shouldSwap, headerConfiguration);

  const { pathname: homePagePathname, query: homePageQuery } = HomePage.route({
    edition,
    slide: "main",
  });
  const isHomepage = router.pathname === homePagePathname;

  const isShowRightFeature = () => {
    if (shouldShowRightFeature) {
      return shouldShowRightFeature;
    }

    return !isHomepage;
  };

  return (
    <Container className={className} style={style}>
      <Wrapper>
        <LeftFeatureContainer>
          <IdleQueue>
            <ActionButton
              $responsiveVariants={{
                desktopUp: "show",
                homeDesktopUp: "hidden",
                mobileUp: "show",
                tabletUp: "show",
              }}
              onClick={() => {
                setHamburgerMenuState(current => ({
                  ...current,
                  isOpen: !current.isOpen,
                }));
                sendGA4Tracking({
                  action: "click",
                  category: "menu",
                  customized_parameters: {
                    action_type: "open",
                    edition,
                    menu_type: "hamburger",
                  },
                  subcategory: "section",
                });
              }}
            >
              <IconMenu />
            </ActionButton>
          </IdleQueue>

          <StyledLogoLink
            anchorProps={{ "aria-label": "SCMP homepage" }}
            as={homeAsPath}
            pathname={homePagePathname}
            query={{ ...homePageQuery, ...mastheadQueryParameter }}
          >
            <StyledLogoFlag />
          </StyledLogoLink>

          {renderFeatures("left")}
          <NoSsr>
            <StyledDynamicHeaderOnelineMenuContainer />
          </NoSsr>
        </LeftFeatureContainer>

        <MobileStyledLogoLink
          anchorProps={{ "aria-label": "SCMP homepage" }}
          as={homeAsPath}
          pathname={homePagePathname}
          query={{ ...homePageQuery, ...mastheadQueryParameter }}
        >
          <StyledLogo />
        </MobileStyledLogoLink>
        {isShowRightFeature() ? (
          <MiniHeaderIndicatorContextProvider>
            <RightFeatureContainer>{renderFeatures("right", "mini-header")}</RightFeatureContainer>
          </MiniHeaderIndicatorContextProvider>
        ) : null}
      </Wrapper>
    </Container>
  );
};

MiniHeader.displayName = "MiniHeader";
